// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
	position: fixed;
	top:0;
	left:0;
	width:100%;
	z-index: 1;
	padding:0 60px;
	nav ul {
		margin:0 -4px;
		@extend .flex;
		@extend .transition;
		li {
			list-style: none;
			padding:0 4px;
			a{
				color:$conifer;
				border:2px solid rgba($conifer, 0.5);
				display: inline-block;
				padding:8px 30px;
				border-radius:30px;
				@include font-size(14px);
				@extend .text-uppercase;
				@extend .bold;
				&:hover {
					background-color: $conifer;
					@extend .text-white;
				}
				&:focus {
					background-color: $conifer;
					@extend .text-white;
				}
			}
			&.active a {
				background-color: $conifer;
				@extend .text-white;
			}
		}
	}
	&::before {
	    content: '';
	    position: absolute;
	    height: 100%;
	    width: 100%;
	    left: 0;
	    top: 0;
	    z-index: -1;
	    @extend .transition;
	}
	&.sticky {
		nav ul { 
			@include prefix((
				transform: translateY(-10px)
			), webkit moz);
		}
		&::before {
		    background-color: #2c2a2b;
		    height: 80px;
		    @include prefix((
				    box-shadow: 0 0 5px rgba(255,255,255,0.2)
		    ), webkit moz);
		}
	}
}
.branding {
	background-image:image('logo.png');
	background-size:contain;
	background-repeat:no-repeat;
	width: 79px;
	height:102px;
}
.hamburger {
	padding:0;
	display:none;
	&-inner {
		background-color: $accent;
		&::before, &::after {
			background-color: $accent;
		}
	}
}
.mask {
	display:none;
}