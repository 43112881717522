// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
	@extend .transition;
	&-me {
		color:$earls-green;
		border:2px solid $conifer;
		border-radius:50px;
		padding:20px 40px;
		line-height:1.7;
		@include on-event {
			background-color: $conifer;
			@extend .text-white;
			svg {
				path{
					fill:$accent
				}
			}
		}
	}
	&-not-available {
		background-color: #ddd;
		pointer-events: none;
	}
}
