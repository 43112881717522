// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
* {
	box-sizing: border-box;
}
html {
	font-size:16px;
}
body {
	font-family: 'Lato', sans-serif;
	line-height:1.5;
	color:#666666;
	@include font-size(16px);
}

a {
	@extend .transition;
	@include on-event {
		text-decoration:none;
	}
}
.container {
	overflow:hidden;
}
.bold {
	font-weight:700;
}
.text {
	&-white {
		color:$accent;
	}
	&-conifer {
		color: $conifer;
	}
	&-normal {
		font-weight:normal;
	}
	&-montserrat {
		font-family: 'Montserrat', sans-serif;
	}
	&-light {
		font-weight:100;
	}
	&-italic {
		font-style:italic
	}
	&-bold {
		font-weight:700;
	}
	&-h4 {
		@include font-size(24px);
		span:last-child {
			color: #666666;
			font-weight: normal;
			margin-left: 15px;
			@include font-size(14px);
		}
	}
	&-red {
		color:#e10505;
	}
}
.transition {
	@include prefix((
		transition: all ease-in-out 0.3s
	), webkit moz);
}
.flex {
	@include flexbox();
	&-horizontal-space-between {
		@include justify-content(space-between);
	}
	&-horizontal-center {
		@include justify-content(center)
	}
	&-vertical-center {
		@include align-items(center);
	}
}