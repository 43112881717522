// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.hero {
	width: 100%;
	position:relative;
	&__mask {
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height: 100%;
		background-color:rgba(#2c2a2b, 0.9);
	}
	&__mask_light {
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height: 100%;
		background-color:rgba(#2c2a2b, 0.3);
	}
	&__content {
		position: relative;
		width: 100%;
		padding: 200px 0 275px;
		color:$accent;
		h1 {
			margin-bottom: 30px;
			@include font-size(26px);
		}
		img {
			margin-bottom: 55px;
		}
		p {
			line-height: 1.7;
		}
		.btn {
			margin-top: 50px;
			@include font-size(18px);
			svg {
				margin: 0 0 0 25px;
				position: relative;
				top:5px;
			}
		}
		&--arrow {
			position: absolute;
			bottom: -6px;
			width: 100%;
			svg {
				width: 100%;
			}
		}
	}
	.carousel {
		position:absolute;
	}
	.carousel, .carousel-inner, .item {
		width: 100%;
		height:100%;
	}
	.item {
		background-size: cover;
		background-attachment:fixed;
	}
}
.pricing {
	position:relative;
	padding:50px 0 230px;

	&::before {
		content:'';
		width: 100%;
		height: 484px;
		position:absolute;
		top: 0;
		left:0;
		background-color:#2c2a2b;
	}
	h2 {
		letter-spacing: 8px;
		font-weight: 600;
		margin-bottom: 80px;
		@include font-size(24px);
	}
	&__selection {
		ul {
			padding: 0;
			margin-bottom: 80px;
			@extend .flex;
			@extend .flex-horizontal-center;
			li {
				list-style: none;
				padding: 0 80px;
				position:relative;
				&::before {
					content:'';
					position: absolute;
					top: 13px;
					left: 50%;
					width:100%;
					height: 8px;
					background-color: #b6bf8c;
				}
				&:last-child {
					&::before{
						display: none;
					}
				}
				span {
					position: absolute;
					top: -28px;
					display: block;
					left: 0;
					text-align: center;
					right: 0;
					color: #b8d734;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 4px;
					@include font-size(14px);
				}
				label {
					width: 30px;
					height: 30px;
					background-color: $accent;
					border-radius: 50%;
					position:relative;
					overflow: hidden;
					cursor: pointer;
					margin-bottom: 0;
					@include prefix((
						box-shadow: 0 0 6px 2px rgba(0,0,0,0.2),
					), webkit moz);
					&::before {
						content:'';
						position:absolute;
						top:0;
						left:0;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						background-color:#03a9f4;
						@include prefix((
							transform: scale(0),
							transform-origin:center,
							transition:transform ease-in-out 0.3s,
							box-shadow: 0 0 6px 2px rgba(0,0,0,0.2)
						), webkit moz);
					}
				}
				input {
					position:absolute;
					left: -9999px;
				}
				input:checked + label:before {
					@include prefix((
						transform: scale(0.65)
					), webkit moz);

				}
			}
		}
	}
	&__subscription {
		&--item {
			border:1px solid #cccccc;
			border-radius:10px;
			overflow:hidden;
			@extend .text-center;
			&__top {
				padding:30px 10px 20px;
				background-color:#2c2a2b;
				margin-bottom: 35px;
				min-height: 170px;
			}
			&__features {
				ul {
					padding:0;
					li {
						padding:20px;
						list-style: none;
						&:nth-child(odd){
							background-color:#f8f8f8;
						}
						span {
							display: block;
							@extend .text-center;
							@extend .bold;
						}
						a {
							background-color:$conifer;
							display: block;
							padding: 10px;
							border-radius: 50px;
							letter-spacing: 1px;
							border:2px solid;
							&:hover {
								background-color: transparent;
								color:$conifer;
							}
							&.btn-not-available {
								background-color: #ddd;
								pointer-events: none;
							}
						}
					}
				}
			}
			.label {
				margin-bottom: 20px;
				display: block;
				white-space: inherit;
				height: 40px;
				@include font-size(18px);
			}
			.payment {
				line-height: 1.32;
				@include font-size(14px);
				span {
					&.price {
						@include font-size(40px);
					}
					display: inline-block;
				}
			}
		}
		&--holder {
			width:25%;
			padding:0 15px;
		}
	}
}
.faq {
	padding:80px 0 140px;
	position:relative;
	background-color:#f8f8f8;
	@include prefix((
		box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2)
	),webkit moz);
	&::before {
		content: '';
	    position: absolute;
	    top: -12px;
	    left: 0;
	    width: 100%;
	    height: 2px;
	    background-image:image('dashed.png');
	}
	.panel {
		background-color: #ffffff;
		padding:0;
		border: 1px solid #d2d2d2;
		border-radius:15px;
		overflow:hidden;
		margin-bottom: 10px;
		@include prefix((box-shadow:none), webkit moz);
		&-heading {
			padding:0;
			background-color: #ffffff;
			a {
				padding: 18px 20px;
				color: #666666;
				&:hover {
					background-color:#f7f7f7;
				}
			}
		}
		&-inner {
		    padding: 20px;
		    border-top: 1px solid #ddd;
		}
	}
}
.contact {
	padding:140px 0 80px;
	background-color:#2c2a2b;
	position:relative;
	&::before {
		content:'';
		position: absolute;
	    top: 12px;
	    left: 0;
	    width: 100%;
	    height: 2px;
	   	opacity: 0.25;
	    background-image:image('dashed-light.png');
	}
	form {
		margin: 50px -5px 0;
	}
	.flex-col {
		margin-bottom: 0;
		padding:0 5px;
		@include flex(1 0 50%)
	}
	label.error {
		top: -8px;
	}
	textarea {
		height: 210px;
	}
	input, textarea {
		padding:20px 25px;
		background-color: #e0dfdf;
		border-radius:10px;
		@include font-size(16px);
	}
	input {
		height: 60px;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	h2 {
		margin-bottom: 10px;
	}
	button {
		background-color: transparent;
		border-radius: 50px;
		border: 2px solid $conifer;
		color: #fff;
		padding: 10px 50px;
		margin-top: 40px;
		&:hover {
			background-color:$conifer;
			color: $accent;
		}
	}

}

.container {
	width: 100% !important;
}
.container-header {
	position: absolute;
	top: -15px;
	left: 0;
	right: 0;
	text-align: center;
}
.container-heading {
	background-image: linear-gradient(to right, #363c1e, #1f6521,#53900f, #53900f , #d6ce15);
	color: #FFFFFF;
	border-radius: 50px;
	padding: 10px 100px;
	line-height: 1.7;
	font-size: larger;
}
.how_it_works {
	padding:20px 40px 20px 40px;
	background-color:#2c2a2b;
	position:relative;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:left;
	&::before {
		content:'';
		position: absolute;
	    top: 12px;
	    left: 0;
	    width: 100%;
	    height: 2px;
	   	opacity: 0.25;
	}
	form {
		margin: 50px -5px 0;
	}
	.flex-col {
		margin-bottom: 0;
		padding:0 5px;
		@include flex(1 0 50%)
	}
	h2 {
		margin-bottom: 10px;
	}
}
.how_it_works_steps {
	padding:20px 40px 20px 40px;
	background-color:#2c2a2b;
	position:relative;
	background-image: linear-gradient(to right, #363c1e, #1f6521,#53900f, #53900f , #d6ce15);
	&::before {
		content:'';
		position: absolute;
	    top: 12px;
	    left: 0;
	    width: 100%;
	    height: 2px;
	   	opacity: 0.25;
	}
	form {
		margin: 50px -5px 0;
	}
	.flex {
		flex-wrap: wrap;
	}
	.flex-col {
		margin-bottom: 0;
		padding:0 5px;
		padding: 35px;
		@include flex(1 0 50%)
	}
	h2 {
		margin-bottom: 10px;
	}
	.img-steps {
		box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.50);
	}
	a {
		background-color: transparent;
		border-radius: 50px;
		border: 2px solid $conifer;
		color: #fff;
		padding: 10px 50px;
		margin-top: 20px;
		margin-bottom: 40px;
		&:hover {
			background-color:$conifer;
			color: $accent;
		}
	}
}
.bubbles {
	position: absolute;
	top: -50px;
	left: 0;
	right: 0;
	text-align: center;
}
.header {
	letter-spacing: 8px;
	margin-bottom: 45px;
	@include font-size(24px);
}
.list-sepa {
	margin-bottom: 25px;
	width: 100%;
	height: 1px;
}