// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
	background-color:#2c2a2b;
}
.footer {
	padding:40px 0 100px;
	border-top:1px solid #545253;
	@include font-size(14px);
}