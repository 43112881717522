// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
.loader-small {
	img {
		width:100px;
	}
}
.have-an-account {
	margin-top:20px;
	@include font-size(14px);
	white-space:nowrap;
	&:hover {
		color:$conifer;
	}
}
a.cc-link {
	display: none;
}
.back-to-top {
    position: fixed;
    z-index: 9;
    cursor: pointer;
    background-color: #b8d734;
    bottom: 1em;
    right: -100px;
    width: 50px;
    height: 50px;
    opacity:0.8;
    @include prefix((
		transition:all 0.5s ease-in-out,
    ), webkit moz);
    &:hover {
		opacity:1
	}
	&.is-active {
		right:1em
	}
}