/*animations*/


.animated { 
    -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
} 
.slow{
     -webkit-animation-duration: 1.5s; 
    animation-duration: 1.5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
}
.slower{
     -webkit-animation-duration: 2s; 
    animation-duration: 2s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
}
.slowest{
     -webkit-animation-duration: 3s; 
    animation-duration: 3s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
}

/* Added by Andy Meetan */
.delay-250 {
    -webkit-animation-delay:0.25s;
    -moz-animation-delay:0.25s;
    -o-animation-delay:0.25s;
    animation-delay:0.25s;
}
.delay-500 {
    -webkit-animation-delay:0.5s;
    -moz-animation-delay:0.5s;
    -o-animation-delay:0.5s;
    animation-delay:0.5s;
}
.delay-750 {
    -webkit-animation-delay:0.75s;
    -moz-animation-delay:0.75s;
    -o-animation-delay:0.75s;
    animation-delay:0.75s;
}
.delay-1000 {
    -webkit-animation-delay:1.0s;
    -moz-animation-delay:1.0s;
    -o-animation-delay:1.0s;
    animation-delay:1.0s;
}
.delay-1250 {
    -webkit-animation-delay:1.25s;
    -moz-animation-delay:1.25s;
    -o-animation-delay:1.25s;
    animation-delay:1.25s;
}
.delay-1500 {
    -webkit-animation-delay:1.5s;
    -moz-animation-delay:1.5s;
    -o-animation-delay:1.5s;
    animation-delay:1.5s;
}
.delay-1750 {
    -webkit-animation-delay:1.75s;
    -moz-animation-delay:1.75s;
    -o-animation-delay:1.75s;
    animation-delay:1.75s;
}
.delay-2000 {
    -webkit-animation-delay:2.0s;
    -moz-animation-delay:2.0s;
    -o-animation-delay:2.0s;
    animation-delay:2.0s;
}
.delay-2500 {
    -webkit-animation-delay:2.5s;
    -moz-animation-delay:2.5s;
    -o-animation-delay:2.5s;
    animation-delay:2.5s;
}
.delay-2000 {
    -webkit-animation-delay:2.0s;
    -moz-animation-delay:2.0s;
    -o-animation-delay:2.0s;
    animation-delay:2.0s;
}
.delay-2500 {
    -webkit-animation-delay:2.5s;
    -moz-animation-delay:2.5s;
    -o-animation-delay:2.5s;
    animation-delay:2.5s;
}
.delay-3000 {
    -webkit-animation-delay:3.0s;
    -moz-animation-delay:3.0s;
    -o-animation-delay:3.0s;
    animation-delay:3.0s;
}
.delay-3500 {
    -webkit-animation-delay:3.5s;
    -moz-animation-delay:3.5s;
    -o-animation-delay:3.5s;
    animation-delay:3.5s;
}

.bounceInRight, .bounceInLeft, .bounceInUp, .bounceInDown{
    opacity:0;
    -webkit-transform: translateX(400px); 
    transform: translateX(400px); 
}
.fadeInRight, .fadeInLeft, .fadeInUp, .fadeInDown{
    opacity:0;
    -webkit-transform: translateX(400px); 
    transform: translateX(400px); 
}

.flipInX, .flipInY, .rotateIn, .rotateInUpLeft, .rotateInUpRight, .rotateInDownLeft, .rotateDownUpRight, .rollIn{
    opacity:0;
}

.lightSpeedInRight, .lightSpeedInLeft{
    opacity:0;
    -webkit-transform: translateX(400px); 
    transform: translateX(400px); 
}

/*********************
* fadeInUpShort
*********************/
@-webkit-keyframes fadeInUpShort { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateY(20px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateY(0); 
    } 
} 

@keyframes fadeInUpShort { 
    0% { 
        opacity: 0; 
        transform: translateY(20px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateY(0); 
    } 
} 
.fadeInUpShort{
    opacity: 0; 
    -webkit-transform: translateY(20px); 
    transform: translateY(20px); 
}
.fadeInUpShort.go { 
    -webkit-animation-name: fadeInUpShort; 
    animation-name: fadeInUpShort; 
}
