@charset 'UTF-8';


// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Plugins
@import
  'plugins/animations/animations',
  'plugins/bootstrap/bootstrap',
  'plugins/flex/flex',
  'plugins/flex/flex-extension',
  'plugins/hamburgers/hamburgers',
  'plugins/cookieconsent/cookieconsent';

// 3. Base stuff
@import
  'base/base',
  'base/typography';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer';

// 5. Components
@import
  'components/button';

// 6. Page-specific styles
@import
  'pages/registration',
  // 'pages/inclusion',
  'pages/home';

// 7. Themes
@import
  'themes/default',
  'themes/media';

