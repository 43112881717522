/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables";
@import "mixins";

// Reset and dependencies
@import "normalize";
@import "print";
@import "glyphicons";

// Core CSS
@import "scaffolding";
@import "type";
// @import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";

// Components
@import "component-animations";
// @import "dropdowns";
// @import "button-groups";
// @import "input-groups";
// @import "navs";
// @import "navbar";
// @import "breadcrumbs";
// @import "pagination";
// @import "pager";
// @import "labels";
// @import "badges";
// @import "jumbotron";
// @import "thumbnails";
// @import "alerts";
// @import "progress-bars";
// @import "media";
// @import "list-group";
@import "panels";
// @import "responsive-embed";
// @import "wells";
// @import "close";

// Components w/ JavaScript
@import "modals";
// @import "tooltip";
// @import "popovers";
@import "carousel";

// Utility classes
@import "utilities";
@import "responsive-utilities";
