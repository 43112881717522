.registration {
	position:relative;
	min-height:100vh;
	background-image:image('registration-bg.jpg');
	background-size:cover;
	&::before, &::after {
		content:'';
		position:absolute;
		top:0;
		width:50%;
		height: 100%;
	}
	&::before {
		left:0;
		background-color:rgba(#2c2a2b, 0.82);
	}
	&::after {
		right:0;
		background-color:#2c2a2b;
	}
	&__top {
		padding:1.8229166666666667vw;
		position:relative;
		z-index: 1;
		.btn {
			width: 200px;
			@include font-size(18px);
			@extend .flex;
			@extend .flex-vertical-center;
			@extend .flex-horizontal-center;
			svg {
				margin-left: 20px;
				@include prefix((
				    transform: rotate(180deg),
				    transform-origin:left
				), webkit moz);
			}
		}
	}
	&__body {
		position:relative;
		z-index: 1;
		min-height: calc(100vh - 149px);
		&--left, &--right {
			padding:5.208333333333334vw 1.5625vw 1.5625vw;
			@include flex(1 0 50%);
			@extend .flex;
			@extend .flex-horizontal-center;
		}
		&--left {
			@include flex-wrap(wrap);
			span {
				@extend .text-center;
				img {
					max-width: 95%;
				}
			}
		}
		input {
			height: 60px;
			padding: 0 25px;
			margin-bottom: 10px;
			border-radius: 10px;
			@include font-size(16px);
		}
		button {
			background-color: transparent;
			border-radius: 50px;
			border: 2px solid #B8D734;
			color: #fff;
			padding: 10px 50px;
			margin-top: 20px;
			outline-style: none;
			display: inherit;
			&:hover, &:active, &:focus {
				background-color: #B8D734;
				color: #fff;
				outline-style: none;
			}
		}
		form {
			width: 100%;
			max-width: 500px;
		}
		&--checkout form {
			max-width: 700px;
		}
	}
	h1 {
		font-weight: 600;
		letter-spacing: 5px;
		margin:0 0 30px;
		@include font-size(24px);
		@extend .text-center;
	}
}
label.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 2px 8px;
    border-radius: 4px;
    margin: -10px 0 10px;
    display: inline-block;
    position: relative;
    @include font-size(14px);
    &::after {
    	content: '';
	    width: 0;
	    height: 0;
	    border-left: 7px solid transparent;
	    border-right: 7px solid transparent;
	    border-bottom: 7px solid #f2dede;
	    position: absolute;
	    top: -7px;
	    left: 7px;
	    font-size: 9px;
    }
    &:last-child {
    	margin-bottom: 0;
    }
}
.copyright {
	width:50%;
	color: $accent;
	opacity:0.35;
	@extend .text-center;
	@include font-size(14px);
	position:absolute;
	left:25%;
	bottom:30px;
	z-index: 9;
	@include prefix((
		transform: translateX(-50%)
	), webkit moz);
	a {
		color: $accent;
	}
}
.review-product {
	background-color: #fff;
	table {
		tbody tr td {
			vertical-align: middle
		}
	}

}
a.view-details{
    background-color: #b8d734;
    display: inline-block;
    border-radius: 50px;
    padding: 7px 15px;
    color: $accent;
    font-weight: 700;
    letter-spacing: 1px;
    @include font-size(12px);
    &:hover {
    	background-color: darken(#b8d734, 12%)
    }
}
.close.product-review-close-modal {
    position: absolute;
    right: -17px;
    top: -15px;
    background-color: #F44336;
    opacity: 1;
    color: $accent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    @include prefix((
		text-shadow: none
    ), webkit moz);
    &:hover {
    	background-color:#d62f23;
    }
}
div#product-review {
	.pricing__subscription--item {
		&__top {
			margin-bottom: 0;
			padding: 15px 10px;
		}
		&__features ul li{
			padding: 10px;
		}
	}
}