@include breakpoint('desktop-md'){
	.registration__body,
	.registration__top {
		&--left, &--right {
			padding:  30px;
		}
	}
	.registration__body input {
		height: 55px;
	}
}
@include breakpoint('tablet'){
	html {
		font-size: 15px;
	}
	.registration__body {
		&--left, &--right {
			padding:  30px 20px;
		}
		input {
			height: 50px;
		}
	}
}
@include breakpoint('tablet-port'){
	body {
		&.is-active {
			overflow:hidden;
		}
	}
	header {
		padding: 0 20px;
		nav {
			position: fixed;
			right: 0;
			top: 0;
			height: 100%;
			width: 200px;
			background-color: #2c2a2b;
			z-index: 1;
			@include prefix((
				transition:transform 0.3s ease-in-out,
				transform: translateX(100%)
			), webkit moz);
			ul {
				display: block;
				margin: 0;
				padding: 0;
				li a{
					display: block;
					text-align: center;
					border-radius: 0;
					border: 0;
					border-bottom: 1px solid;
					padding: 25px 30px;
				}
			}
			&.is-active {
				@include prefix((
					transform: translateX(0)
				), webkit moz);
			}
		}
		&.sticky::before {
			height: 62px;
		}
	}
	.registration__body {
		&--right {
			padding-top: 8vh;
		}
	}
	.branding {
		width: 60px;
		height: 79px;
	}
	.hero__content {
		br {
			display: none;
		}
		img {
			max-width: 95%;
		}
	}
	.pricing{
		padding:50px 0;
		&__subscription{
			&--item {
				margin-bottom: 30px;
			}
			&--holder {
				width: 50%;
			}
		}
	}
	.hamburger {
		margin-top: -11px;
		display: block;
		@include prefix((
			transition:transform 0.3s ease-in-out,
		), webkit moz);
		&.is-active {
			@include prefix((
				transform: translateX(-200px)
			),webkit moz);
		}
	}
	.mask {
		display: block;
		&.is-active {
			position:fixed;
			top:0;
			left:0;
			width:100%;
			height: 100%;
			background-color:rgba(#2c2a2b, 0.8);
		}
	}
	.registration{
		&::before, &::after {
			width:100%;
			height: 50%;
		}
		&::after{
			top:initial;
			bottom:0;
		}
		&__body {
			@include flex-wrap(wrap);
			&--left, &--right {
				@include flex(1 0 100%);
			}
			&--left {
				padding-bottom: 8vh ;
			}
			&--right {
				background: #2c2a2b;
			}
		}
	}
	.copyright {
		position:relative;
		left:0;
		bottom: 0;
		margin:50px 0 0;
		padding:30px 0;
		border-top:1px solid #545253;
		width: 100%;
		@include prefix((
			transform: translateX(0)
		), webkit moz);
	}
}
@include breakpoint('phone-md') {
	html {
		font-size: 14px;
	}
	.registration__top {
		padding: 15px;
		.btn {
			width: 50px;
			padding: 0;
			height: 50px;
			text-align: center;
			span {
				display: none;
			}
			svg {
				margin: 0 -46px 0 0;
			}
		}
	}
	.pricing {
		&__subscription{
			&--holder {
				width: 100%;
			}
		}
		&__selection ul {
			display: block;
			max-width: 200px;
			margin: 0 auto 80px;
			li {
				padding: 30px 0;
				&::before {
					top: inherit;
					left: 12px;
					width: 8px;
					height: 100%;
				}
				span {
					text-align: left;
					top: 35px;
					margin-left: 50px;
				}
			}
		}
		&::before {
		    height: 738px;
		}
	}
	.contact {
		.flex {
			@include flex-wrap(wrap);
			&-col {
				@include flex(auto);
				textarea {
					margin-top: 0;
				}
			}
		}
		.form-group {
			@include flex(1 0 100%);
		}
		textarea {
			margin-top: 10px;
		}
	}
	.how_it_works {
		.flex {
			@include flex-wrap(wrap);
			&-col {
				@include flex(auto);
				textarea {
					margin-top: 0;
				}
			}
		}
		.form-group {
			@include flex(1 0 100%);
		}
		textarea {
			margin-top: 10px;
		}
	}
	.how_it_works {
		padding: 0px;
	}
	.how_it_works_steps {
		padding: 0px;
		.flex-col {
			margin-bottom: 0;
			padding:20px 20px 0px 20px;
			@include flex(1 0 100%)
		}
	}
}
@include breakpoint('phone-xs'){
	.btn-me {
		padding:20px 30px;
	}
	.registration__actions {
		button {
			display: inline-block;
			text-align:center;
			width:100%;
		}
	}
	.container-heading {
		padding: 10px 70px;
	}
}