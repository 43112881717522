// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}


/// Rem font size with px fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) { 
  font-size: $size;
  font-size: calculateRem($size);
}


/// Mixin to prefix several properties at once
/// @author Hugo Giraudel
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}


/// Breakpoint
@mixin breakpoint($break) {
    @if $break == hd {
        @media only screen and (min-width: 1919px) { @content; }
    } @else if $break == desktop-lg {
        @media only screen and (max-width: 1600px) { @content; }
    } @else if $break == desktop-md {
        @media only screen and (max-width: 1400px) { @content; }
    } @else if $break == desktop-sm {
        @media only screen and (max-width: 1366px) { @content; }
    } @else if $break == desktop-xs {
        @media only screen and (max-width: 1280px) { @content; }
    } @else if $break == tablet {
        @media only screen and (max-width: 1200px) { @content; }
    } @else if $break == tablet-port {
        @media only screen and (max-width: 800px) { @content; }
    } @else if $break == phone-lg {
        @media only screen and (max-width: 767px) { @content; }
    } @else if $break == phone-md {
        @media only screen and (max-width: 600px) { @content; }
    } @else if $break == phone-sm {
        @media only screen and (max-width: 480px) { @content; }
    } @else if $break == phone-xs {
        @media only screen and (max-width: 400px) { @content; }


    } @else {
        @media only screen and ($break) { @content; }
    }
}



